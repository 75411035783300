.footer {
  padding: 149px 10px 10px;
}

.footer__title {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #a0a0a0;
}

.footer__title::after {
  content: "";
  border-bottom: 1px solid #e8e8e8;
  display: block;
  padding-top: 21px;
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 27px;
}

.footer__social {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.footer__social-link {
  text-decoration: none;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
}

.footer__copy {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #a0a0a0;
}

@media screen and (min-width: 640px) {
  .footer {
    padding: 79px 30px 20px;
  }
  .footer__title {
    font-size: 13px;
    line-height: 16px;
  }
  .footer__wrapper {
    padding-top: 18px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .footer__social {
    flex-direction: row;
    gap: 20px;
  }
  .footer__social-link {
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
  }

  .footer__copy {
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }
}
@media screen and (min-width: 1024px) {
  .footer {
    padding: 79px 70px 20px;
  }
}

.portfolio {
  margin: 0;
  padding: 72px 14px 0;
}

.portfolio__title {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  color: #a0a0a0;
}

.portfolio__list {
  list-style: none;
  padding: 43px 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.portfolio__item {
  display: flex;
  justify-content: space-between;
}

.portfolio__item:nth-child(-n + 2)::after {
  content: "";
  display: block;
  border-bottom: 1px solid #e6e6e6;
  width: calc(100% - 28px);
  position: absolute;
  padding-bottom: 46px;
}

.portfolio__link {
  text-decoration: none;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: #000000;
  width: 100%;
  position: relative;
}

.portfolio__link-icon {
  background: no-repeat url("../../images/portfolio/link-ico.svg");
  height: 28px;
  width: 18px;
  position: absolute;
  top: 6px;
  right: -3px;
}

@media screen and (min-width: 640px) {
  .portfolio {
    padding: 91px 50px 90px;
  }
  .portfolio__title {
    font-size: 18px;
    line-height: 20px;
  }
  .portfolio__list {
    padding-top: 49px;
  }
  .portfolio__item:nth-child(-n + 2)::after {
    padding-bottom: 69px;
    width: calc(100% - 100px);
  }
  .portfolio__link {
    font-size: 28px;
    line-height: 50px;
  }
  .portfolio__link-icon {
    width: 27px;
    background-size: 17px;
    top: 18px;
    right: -5px;
  }
}
@media screen and (min-width: 1024px) {
  .portfolio {
    padding: 101px 70px 125px;
  }

  .portfolio__item:nth-child(-n + 2)::after {
    padding-bottom: 69px;
    width: calc(100% - 140px);
  }
  .portfolio__link {
    font-size: 30px;
    line-height: 50px;
    z-index: 2;
  }
  .portfolio__link-icon {
    background-size: 18px;
    right: -3px;
  }
}

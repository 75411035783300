@keyframes animateModal {
  0% {
    top: 0px;
    right: -600px;
    bottom: 0px;
  }
  100% {
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
}

.modal-menu {
  position: absolute;
  display: flex;
  max-width: 520px;
  background: #ffffff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  animation-name: animateModal;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
}

.modal-menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: green;
  background: rgba(0, 0, 0, 0.3);
  height: 1000px;
}

.modal-menu__close-button {
  position: absolute;
  right: 18px;
  top: 18px;
  height: 22px;
  width: 22px;
  background: no-repeat center url("../../images/modal/close-min.svg") #fff;
  border: none;
  cursor: pointer;
}
.modal-menu__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 145px 0 0;
  align-items: center;
  gap: 24px;
}
.modal-menu__item {
}
.modal-menu__link {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #000000;
}
.modal-menu__profile-button {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  width: 100px;
  height: 32px;
  padding: 0 0 0 11px;
  margin: 443px auto 0;
  align-items: center;
  text-decoration: none;
}
.modal-menu__profile-ico {
  box-sizing: border-box;
  background: no-repeat center
    url("../../images/burger-menu/burger-ico-min.svg") #f9f9f9;
  background-size: 12px 12px;
  height: 26px;
  width: 26px;
  border-radius: 50px;
  margin-right: 3px;
}

@media screen and (min-width: 640px) {
  .modal-menu__close-button {
    right: 27px;
    top: 27px;
  }
  .modal-menu__list {
    padding-top: 160px;
    gap: 28px;
  }
  .modal-menu__profile-button {
    margin-top: 400px;
  }
}

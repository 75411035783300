.section__title {
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding-top: 71px;
  letter-spacing: -0.04em;
}

.section__title::after {
  content: "";
  border-bottom: 1px solid black;
  display: block;
  padding-top: 27px;
  margin-bottom: 61px;
}

@media screen and (min-width: 640px) {
  .section__title {
    font-size: 22px;
    line-height: 27px;
    padding-top: 91px;
  }
  .section__title::after {
    padding-top: 22px;
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 1024px) {
  .section__title {
    padding-top: 101px;
  }
}

.films {
  padding: 0 10px 10px;
}
.films__cards {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 33px;
}
@media screen and (min-width: 640px) {
  .films {
    padding: 30px 30px 80px;
  }
  .films__cards {
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .films {
    padding: 30px 70px 80px;
  }
  .films__cards {
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
  }
}

.more-button {
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #f9f9f9;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 61px;
}

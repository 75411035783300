.burger-menu {
  display: none;
  align-items: center;
}
.burger-menu__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 10px;
}

.burger-menu__link {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

@media screen and (min-width: 1024px) {
  .burger-menu {
    display: flex;
    align-items: center;
  }
  .burger-menu__list {
    gap: 20px;
    padding-right: 49px;
    flex-direction: row;
  }
  .burger-menu__link {
    font-size: 14px;
    line-height: 18px;
  }

  .burger-menu__link_type_main {
    display: none;
  }
  .burger-menu_place_landing {
    display: flex;
  }
}

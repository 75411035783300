.search {
  padding: 80px 14px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0;
  box-sizing: border-box;
}
.search::after {
  content: '';
  display: block;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  padding-top: 49px;
}

.search__wrapper {
  position: relative;
  width: 100%;
}

.search__form {
  position: relative;
}

.search__form-input {
  width: 100%;
  height: 46px;
  padding-left: 19px;
  border: none;
  border-radius: 40px;
  color: #a0a0a0;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  background: #f9f9f9;
}
.search__input::-webkit-input-placeholder {
  color: #a0a0a0;
  background: #f9f9f9;
}
.search__input:-moz-placeholder {
  color: #a0a0a0;
  background: #f9f9f9;
}
.search__input:-ms-input-placeholder {
  color: #a0a0a0;
  background: #f9f9f9;
}

.search__input:focus {
  outline: none;
  border: none;
}

.search__form-submit {
  border: none;
  outline: none;
  padding: 0;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  background: no-repeat center url(../../images/search/find-min.svg);
  background-size: 34px 34px;
}

.search__form-error {
  position: absolute;
  font-size: 13px;
  line-height: 17px;
}

.search__checkbox {
  margin-top: 42px;
}

.search__checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}
.search__checkbox-text {
  position: relative;
  padding: 0 0 0 50px;
  cursor: pointer;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
}
.search__checkbox-text:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 36px;
  height: 20px;
  border-radius: 13px;
  background: #cdd1da;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
.search__checkbox-text:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}
.search__checkbox-input:checked + .search__checkbox-text:before {
  background: #2be080;
}
.search__checkbox-input:checked + .search__checkbox-text:after {
  left: 18px;
}

@media screen and (min-width: 640px) {
  .search {
    padding: 80px 30px 50px;
  }
  .search__form-input {
    font-size: 13px;
    line-height: 17px;
    padding-left: 22px;
  }

  .search__checkbox-text {
    padding-left: 48px;
    font-size: 14px;
  }
  .search__checkbox-text:before {
    top: -2px;
  }
  .search__checkbox-text:after {
    top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .search {
    padding: 60px 70px 50px;
  }
  .search::after {
    padding-top: 69px;
  }
}

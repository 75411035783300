@import url('../../vendor/fonts/Inter/inter.css');
@import url('../../vendor/normalize.css');

@import url('../Section/Section.css');

.App {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  transition: opacity ease-in-out 0.3s;
}
.button:hover {
  opacity: 0.8;
}

button:disabled {
  background: #f8f8f8;
  color: #c2c2c2;
}

.link {
  transition: opacity ease-in-out 0.3s;
}
.link:hover {
  opacity: 0.7;
}
.link_active {
  position: relative;
}
.link_active::after {
  content: '';
  position: absolute;
  display: block;
  border-bottom: 2px solid #000000;
  width: 100%;
  top: 28px;
}
.hidden {
  display: none;
}
@media screen and (min-width: 640px) {
  .link_active::after {
    top: 25px;
  }
}

.burger-button {
  padding: 0;
  margin: 0;
  width: 40px;
  height: 43px;
  background: #fff;
  border: none;
  position: relative;
  cursor: pointer;
}

.burger-button__ico,
.burger-button__ico::before,
.burger-button__ico::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -11px;
  width: 22px;
  height: 2.5px;
  background: #000000;
}

.burger-button__ico::before,
.burger-button__ico::after {
  content: "";
}

.burger-button__ico::before {
  transform: translateY(-9px);
}

.burger-button__ico::after {
  transform: translateY(9px);
}

.burger-button_place_landing {
  background: #f3c1f8;
}

@media screen and (min-width: 640px) {
  .burger-button {
    width: 44px;
    height: 44px;
  }
  .burger-button__ico,
  .burger-button__ico::before,
  .burger-button__ico::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -14px;
    width: 28px;
    height: 2.5px;
    background: #000000;
  }
  .burger-button__ico::before {
    transform: translateY(-10px);
  }

  .burger-button__ico::after {
    transform: translateY(10px);
  }
}
@media screen and (min-width: 1024px) {
  .burger-button {
    display: none;
  }
}

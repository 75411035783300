.techs {
  padding: 0 18px;
}

.techs__title {
  margin: 0 auto;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__subtitle {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 23px;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__list {
  margin: 0;
  padding: 50px 35px 0;
  list-style: none;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 84px);
  justify-content: center;
}

.techs__list-item {
  font-weight: normal;
  font-size: 12px;
  line-height: 57px;
  text-align: center;
  letter-spacing: -0.04em;
  background: #e8e8e8;
  border-radius: 10px;
  width: 84px;
  min-height: 57px;
}

@media screen and (min-width: 640px) {
  .techs {
    padding: 0 50px 89px;
    background: #f5f5f5;
  }
  .techs__title {
    font-size: 50px;
    line-height: 58px;
    padding-top: 10px;
  }
  .techs__subtitle {
    font-size: 12px;
    line-height: 18px;
  }
  .techs__list {
    padding: 83px 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .techs {
    padding: 0 70px 100px;
    background: #f5f5f5;
  }
  .techs__title {
    padding-top: 20px;
  }
  .techs__subtitle {
    font-size: 14px;
    line-height: 20px;
    padding-top: 26px;
    max-width: 460px;
  }
  .techs__list {
    padding: 100px 0 0;
    gap: 14px;
  }
  .techs__list-item {
    width: 90px;
    min-height: 60px;
    font-size: 14px;
  }
}
